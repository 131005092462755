import React, { useState } from 'react';
import { Navbar, Container, Nav, NavDropdown, Offcanvas, Button, CloseButton, Accordion, ProgressBar } from 'react-bootstrap';
import logo from '../../../assets/img/logo.png';
import cartIcon from '../../../assets/svgs/cart.svg';
import accountIcon from '../../../assets/svgs/account.svg';
import './Header.css'; // Import header styles
import { Search } from 'react-bootstrap-icons';
import { Digestive, ImmunityThroat, Women, Men, Joint, Oral, Personal, Sexual, StressAnxietySleep, PlusIcon, MinusIcon } from '../Svg';
import CartItem from '../CartItem';
import Coupon from '../Coupon';
import '../../cartpopup/cartpopup.css'; // Import header styles
import ClubMemberCart from '../../cartpopup/ClubMemberCart';
import  BreakupImage from '../../../assets/img/breakup.png';

const Header = () => {

  const [show, setShow] = useState(false);
  const [showShop, setShowShop] = useState(false);
  const [showPriceBreakup, setShowPriceBreakup] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleShopClose = () => setShowShop(false);
  const handleShopShow = () => setShowShop(true);

  const handlePriceBreakupClose = () => setShowPriceBreakup(false);
  const handlePriceBreakupShow = () => setShowPriceBreakup(true);

  return (
    <>
    <Navbar bg="light" expand="lg" sticky="top" className='pt-0'>
      <div className="topbar notification w-100 dark-theme d-flex align-items-center">
        <div className="marquee-tick d-flex align-items-center">
          <div className="track d-flex">
            <div className="content">&nbsp;Use Code “VEDISTRY25” to avail flat 25% off on all products (Exclusing offer products & kits)</div>
            <div className="content">&nbsp;Use Code “VEDISTRY25” to avail flat 25% off on all products (Exclusing offer products & kits)</div>
          </div>
        </div>
      </div>
      <Container className='position-relative logo-bar'>
        <Navbar.Brand href="/" className='top-50 start-50 translate-middle position-absolute order-2'>
          <img
            src={ logo }
            height="51"
            width="82"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className='order-1 border-0 p-0' />
        <Navbar.Collapse className='p-3' id="basic-navbar-nav">
          <Nav className="me-auto">  
            <Nav.Link href="/collections">
              <ImmunityThroat />
              Immunity & Throat Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#2">
              <Joint />
              Joint Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#3">
              <StressAnxietySleep />
              Stress Anxiety & Sleep Disturbance
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#4">
              <Personal />
              Personal Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#5">
              <Women />
              Women's Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#6">
              <Digestive />
              Digestive Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#7">
              <Sexual />
              Sexual Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#8">
              <Oral />
              Oral Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#9">
              <Men />
              Men's Health
            </Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#10">View All Products</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#11" onClick={handleShopShow}>Shop <PlusIcon /></Nav.Link>
            <NavDropdown.Divider />
          </Nav>
          <Nav>
            <Nav.Link href="/about">About</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#11">Why Ayurveda</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="/blogs">Blogs</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#12">Vedistry TV</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#13">News & Article</Nav.Link>
            <NavDropdown.Divider />
            <Nav.Link href="#14">Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <div className='order-3 align-items-center d-flex flex-nowrap'>
          <a className='nav-link' href="#cart" alt="Cart Icon" id="cartIcon" onClick={handleShow}>
            <img src={cartIcon} alt="cart" />
            <span id="cartCount">2</span>
          </a>
          <a className='nav-link ps-4' href="#account" alt="Account Icon"><img src={accountIcon}  alt="account" /></a>
        </div>
      </Container>
      <Container>
        <div className="input-group mt-2 mb-1 border header-search">
          <div className="input-group-prepend border-0">
            <button id="button-addon4" type="button" className="btn btn-link text-info text-dark d-flex align-items-center">
              <Search />
            </button>
          </div>
          <input type="search" placeholder="What are you looking for today?" aria-describedby="button-addon4" className="form-control bg-none border-0" />
        </div>
      </Container>
    </Navbar>
    <Offcanvas show={show} onHide={handleClose} placement="end" className="cart-popup-sidebar">
      <Offcanvas.Header>
        <Offcanvas.Title>
          <span className='offcanvas-title-text mb-3' onClick={handleClose}>
            <CloseButton />You are ₹199 away from free delivery 
          </span>
          <ProgressBar now={60} />
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <CartItem />
        <CartItem />
        <ClubMemberCart />
        <div className='fixed-align'>
        <Coupon />
        <div className='cart-price-section p-3'>
          {showPriceBreakup && 
            <div className='price-breakup'>
              <div className='items-price flex-align-div'>
                <div className=''> <span> 2 Items in Bag</span></div>
                <div className=''><span> ₹639.00</span></div>
              </div>
              <div className='shipping-price flex-align-div'>
                <div className=''>shipping</div>
                <div className=''>₹50.00</div>
              </div>
              <div className='gst-price flex-align-div'>
                <div className=''>GST.</div>
                <div className=''>₹12.00</div>
              </div>
            </div>
          }
          <div className='total-price'>
            <div className='total-price-title'>Total Bill: </div>
            <div className='total-price-count'> <span>₹699.00</span></div>
          </div>
          {!showPriceBreakup && 
            <div className='price-break-link' onClick={handlePriceBreakupShow}><img src={BreakupImage} alt='Breakup' /> View Breakup</div>
          }
          {showPriceBreakup && 
            <div className='price-break-link ' onClick={handlePriceBreakupClose}><CloseButton /> Close Break</div>
          }
        </div>
        {!showPriceBreakup && 
          <div className='place-order-btn mt-4'> 
            <Button>Place Order</Button>
          </div>
        }
        </div>
      </Offcanvas.Body>
    </Offcanvas>


    <Offcanvas show={showShop} onHide={handleShopClose} className="margin-top-115 shop-sub-menu">
      <Offcanvas.Body>
        <Nav.Link href={void(0)} onClick={handleShopClose}><MinusIcon /> Shop</Nav.Link>
        <Accordion flush>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Shop by Category</Accordion.Header>
            <Accordion.Body>
              <Nav.Link href="#555">Immunity & Throat Health</Nav.Link>
              <Nav.Link href="#5555">Joint Health</Nav.Link>
              <Nav.Link href="#55555">Stress Anxiety & Sleep Disturbance</Nav.Link>
              <Nav.Link href="#555555">Personal Health</Nav.Link>
              <Nav.Link href="#5555555">Women's Health</Nav.Link>
              <Nav.Link href="#55555555">Digestive Health</Nav.Link>
              <Nav.Link href="#555555555">Sexual Health</Nav.Link>
              <Nav.Link href="#5555555555">Oral Health</Nav.Link>
              <Nav.Link href="#55555555555">Men's Health</Nav.Link>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Shop by Concern</Accordion.Header>
            <Accordion.Body>
            <Nav.Link href="#555">Immunity & Throat Health</Nav.Link>
              <Nav.Link href="#5555">Joint Health</Nav.Link>
              <Nav.Link href="#55555">Stress Anxiety & Sleep Disturbance</Nav.Link>
              <Nav.Link href="#555555">Personal Health</Nav.Link>
              <Nav.Link href="#5555555">Women's Health</Nav.Link>
              <Nav.Link href="#55555555">Digestive Health</Nav.Link>
              <Nav.Link href="#555555555">Sexual Health</Nav.Link>
              <Nav.Link href="#5555555555">Oral Health</Nav.Link>
              <Nav.Link href="#55555555555">Men's Health</Nav.Link>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Offcanvas.Body>
    </Offcanvas>
    </>
  );
};

export default Header;