import React from "react";

export default function ClubMemberCart (){
    return (
        <div className="club-member-plan">
            <div className="club-member-plan-section1">
                <div className="left-part">club member</div>
                <p className="club-mamber-para">Become a Vedistry club member & earn glow points on all orders</p>
            </div>
            <div className="club-member-description">
                Save ₹54 more on this order with Vedistry Delux and enjoy Unlimited Free Deliveries.
            </div>
            <div className="club-member-plan-btn">
                <button>Buy Plan ₹299</button>
            </div>
        </div>
    )
}