import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Breadcrumb, Col, Container, Row } from "react-bootstrap";
// import ImageSlider from "../components/product/ImageSlider";
import ClubMemberPlan from "../components/common/ClubMemberPlan";
import ProductDescription from "../components/product/productDescription";
import Ingredients from "../components/product/ingredients";
import IngredientImage1 from '../assets/img/ingre-1.png'
import IngredientImage2 from '../assets/img/ingre-2.png'
import IngredientImage3 from '../assets/img/ingre-3.png'
import IngredientImage4 from '../assets/img/ingre-4.png'
import FAQ from "../components/product/faq";
import CustomerReview from "../components/product/customerReview";
import '../assets/css/product-details.css';
import HeadingViewAll from "../components/common/HeadingViewAll";
import AyurvedicImage from '../assets/img/100-ayurvedic.png';
import FormulatedImage from '../assets/img/formulated-by-doctor.png';
import FamilyImage from '../assets/img/suitable-for-complete-family.png';
import VeganImage from '../assets/img/vegan.png';

const Products = () => {

    var settings = {
        autoplay: false,
        autoplaySpeed: 3000,
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };

    const ingredientsValue = [
        {
            title: "Yashtimadhu",
            image: IngredientImage1
        },
        {
            title: "Usheer",
            image: IngredientImage2
        },
        {
            title: "Lodhra",
            image: IngredientImage3
        },
        {
            title: "Jeerak",
            image: IngredientImage4
        }
    ]

    return (
        <Container>
            <Row>
                <div className="single-product">
                    <Breadcrumb>
                        <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                        <Breadcrumb.Item href="#">
                        Digestive Care
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>Ashwagandha Plus Tablets</Breadcrumb.Item>
                    </Breadcrumb>

                    {/* <ImageSlider /> */}

                    <div className="product-rating-title-desc">
                        <div className="rating mt-3 mb-3">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            4.4 | 153 Reviews
                        </div>
                        <h2>Ashwagandha Plus Tablets</h2>
                        <p>Provides natural relief from hyperacidity discomfort. </p>
                    </div>

                    <Row className="mt-5 mb-5">
                        <Col xs={4}>
                            <div className="">Save ₹49</div>
                            <div className="title">Pack of 2</div>
                            <p>100 ml</p>
                            <div className="price">₹549/-</div>
                            <button>Buy Now</button>
                        </Col>
                        <Col xs={4}>
                            <div className="">Save ₹79</div>
                            <div className="title">Pack of 3</div>
                            <p>100 ml</p>
                            <div className="price">759/-</div>
                            <button>Buy Now</button>
                        </Col>
                        <Col xs={4}>
                            <div className="">Save ₹99</div>
                            <div className="title">Pack of 4</div>
                            <p>100 ml</p>
                            <div className="price">1099/-</div>
                            <button>Buy Now</button>
                        </Col>
                    </Row>

                    <div className="total-price-section">
                        <h2 className="mt-3 mb-3">Total: ₹299.00</h2>
                        <button className="primary custom-primary-btn">Add to Cart</button>
                        <p className="mt-2">Delivery in 5 to 7 business days</p>
                    </div>
                    
                </div>

                <div className="basic-info-bar mt-5 mb-5">
                    <div className="topbar notification w-100 dark-theme d-flex align-items-center">
                        <div className="marquee-tick d-flex align-items-center">
                        <div className="track d-flex">
                            <div className="content">&nbsp;<img src={AyurvedicImage} alt="100% Ayurvedic" /> 100% Ayurvedic</div>
                            <div className="content">&nbsp;<img src={FormulatedImage} alt="Formulated by Doctors" /> Formulated by Doctors</div>
                            <div className="content">&nbsp;<img src={FamilyImage} alt="Suitable for complete family" /> Suitable for complete family</div>
                            <div className="content">&nbsp;<img src={VeganImage} alt="Vegan" /> Vegan</div>
                        </div>
                        </div>
                    </div>
                </div>

                <ClubMemberPlan />
                <Slider {...settings} className="product-slide">
                    <ProductDescription />
                    <ProductDescription />
                    <ProductDescription />
                    <ProductDescription />
                    <ProductDescription />
                    <ProductDescription />
                    <ProductDescription />
                </Slider>

                <div className="ingredient-section mt-5">
                    <HeadingViewAll title="Key Ingredients" link="" />
                    <div className="ingredients-section">
                        {ingredientsValue.map(ingre => (
                            <Ingredients data={ingre} />
                        ))}                    
                    </div>
                </div>

                <div className="faq mt-5">
                    <HeadingViewAll title="Have questions? We have got the answers" link="" />
                    <FAQ />
                </div>

                <div className="reviews">
                    <HeadingViewAll title="Customer Reviews" link="" />
                    <CustomerReview />
                </div>
            </Row>
        </Container>
    )
}

export default Products;