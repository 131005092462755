import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ArrowDown } from "./Svg";
import { Table } from "react-bootstrap";

const Product = ({ data }) => {
    let highlightContent;
    if (data.highlight) {
        highlightContent = <span className="highlight">Trending</span>;
    }

    const [showPack, setShowPack] = useState(false);
    const handlePackClose = () => setShowPack(false);
    const handlePackShow = () => setShowPack(true);
    return (        
        <div className="proudct-grid">
            {highlightContent}
            <div className="product-details">
                <div className="product-details-inner">
                    <img src={data.image} alt="product" />
                    <div className="rating">
                        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                        </svg>
                        {data.rating} | {data.reviews} Reviews
                    </div>
                    <div className="review pt-1"></div>
                    <h2 className="data-title">{data.title}</h2>
                    <p className="product-para">{data.desc}</p>
                    <Link className="whole-item-link" to={`/product/ashwagandha-plus-tablets`}></Link>
                </div>
                {!showPack &&
                    <div className="pack-of" onClick={handlePackShow}>
                        Pack of 8 <ArrowDown />
                    </div>
                }
                {showPack && 
                    <div className="pack-of-list">
                        <Table striped hover>
                            <tbody>
                                <tr>
                                    <td>Pack of 8</td>
                                    <td>₹599 <Link className="pack-of-close" to="#" onClick={handlePackClose}><ArrowDown /></Link></td>
                                </tr>
                                <tr>
                                    <td>Pack of 4</td>
                                    <td>₹499</td>
                                </tr>
                                <tr>
                                    <td>Pack of 2</td>
                                    <td>₹399</td>
                                </tr>
                                <tr>
                                    <td>Single Pack</td>
                                    <td>₹249</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                }
                <button className="product-btn">Add to cart ₹{data.price}</button>
            </div>            
        </div>        
    )
}
export default Product;