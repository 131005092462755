import React from "react";

import NewsCard from "../common/NewsCard";
import news1 from '../../assets/img/elle_icon.png'
import news2 from '../../assets/img/dailyhunt_icon.png'
import news3 from '../../assets/img/thetimesofindia.png'
import HeadingViewAll from "../common/HeadingViewAll";

import { A11y, FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

export default function NewsSection(){
    return ( 
        <div className="news-article-section mt-5">
            <HeadingViewAll title="News & Article" link="/news-articles" />
            <div className="carousel-section">
            <div className="carousel-section mobile-full-carousel disable-arrows">
                <Swiper
                    // install Swiper modules
                    modules={[A11y, FreeMode, Navigation]}
                    spaceBetween={10}
                    navigation
                    slidesPerView={1.3}
                    freeMode
                    >
                    <SwiperSlide>
                        <NewsCard title="17 new beauty launches that have us in a chokehold this october" image={news1} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <NewsCard title="Embrace the Ayurveda revolution for deeper winter sleep" image={news2} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <NewsCard title="From Sussanne Khan to Divya Dutta, celebs clicked at Charak Pharma event" image={news3} />
                    </SwiperSlide>
                </Swiper>
                </div>
            </div>
        </div>
    )
}