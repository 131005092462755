import React from 'react';
import { Facebook, Instagram, Linkedin, Youtube } from 'react-bootstrap-icons';

function SocialIcons() {
  return (
    <div className='social-icons'>
      <Facebook size={25} color="white" />
      <Instagram size={24} color="white" />
      <Linkedin size={23} color="white" />
      <Youtube size={29} color="white" />
    </div>
  );
}

export default SocialIcons;