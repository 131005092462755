import React from "react";
import Card from "../common/card";
import SummerDeals1 from '../../assets/img/summer-sale-1.png'
import SummerDeals2 from '../../assets/img/summer-sale-2.png'
import DigestiveCare from '../../assets/img/digestive-care.png'
import DigestiveCare1 from '../../assets/img/digestive-care-1.png'
import HeadingViewAll from './../common/HeadingViewAll';

export default function SummberDeals(){
    return (
        <div className="summer-deals mt-5">

            <HeadingViewAll title="Summer Deals" link="" />
            <div className="summer-deals-cards">
                <Card title="Summer Sale" desc="FLAT 10% OFF" image={SummerDeals1} />
                <Card title="Degestive Care" desc="Buy 1, get 10% OFF" image={DigestiveCare} />
                <Card title="Degestive Care" desc="Buy 1, get 10% OFF" image={DigestiveCare1} />
                <Card title="Summer Sale" desc="FLAT 10% OFF" image={SummerDeals2} />
            </div>
        </div>
    )
}