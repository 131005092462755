import React from "react";
import SingleCategoryGrid from "../common/SingleCategoryGrid";
import jointcare from '../../assets/img/joint-care-category.png';
import personalcare from '../../assets/img/personal-care-category.png';
import menscare from '../../assets/img/mens-care.png';
import womenscare from '../../assets/img/womens-care.png';
import HeadingViewAll from "../common/HeadingViewAll";
import { Container, Row } from 'react-bootstrap';

import { A11y, FreeMode, Navigation } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

export default function ShopByCategory(){
    return (
        <Container>
            <Row>
                <div className="shop-by-category-section mt-5">
                    <HeadingViewAll title="Shop By Category" link="/news-articles" />
                    <div className="carousel-section mobile-full-carousel">
                        <Swiper
                            // install Swiper modules
                            modules={[A11y, FreeMode, Navigation]}
                            spaceBetween={10}
                            navigation
                            slidesPerView={1.8}
                            freeMode
                            >
                            <SwiperSlide><SingleCategoryGrid title="Joint Care" image={jointcare} /></SwiperSlide>
                            <SwiperSlide><SingleCategoryGrid title="Personal Care" image={personalcare} /></SwiperSlide>
                            <SwiperSlide><SingleCategoryGrid title="Men's Care" image={menscare} /></SwiperSlide>
                            <SwiperSlide><SingleCategoryGrid title="Women's Care" image={womenscare} /></SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </Row>
        </Container>
    )
}