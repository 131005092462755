import React from "react";
import descImage from '../../assets/img/product-desc.png';
import HeadingViewAll from "../common/HeadingViewAll";

export default function ProductDescription(){
    return (
        <div className="product-description mt-5">
            <HeadingViewAll title="Product Description" link="" />
            <img src={descImage} alt="product description"></img>
            <ul>
                <li>Protects stomach lining</li>
                <li>Neutralises excess acid</li>
                <li>Prevents recurrence</li>
                <li>Helps relieve heartburn</li>
                <li>Helps prevent & relieve ulcer formation</li>
            </ul>
        </div>
    )
}