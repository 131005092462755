import React from "react";
import { Link } from "react-router-dom";

export default function BlogItem({ image, title, link}){
    return(
        <div className="blog-list">
            <img src={image} alt={title} className="img-fluid" />
            <h2>{title}</h2>
            <Link to={link}>Read More</Link>
        </div>
    )
}