import React from "react";
import BlogCard from "../common/BlogCard";
import blog1 from '../../assets/img/blog-1.png';
import blog2 from '../../assets/img/blog-2.png';
import blog3 from '../../assets/img/blog-3.png';
import HeadingViewAll from "../common/HeadingViewAll";

import { A11y, FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

export default function BlogSection(){
    return (
        <div className="blog-section mt-5">
            <HeadingViewAll title="Latest Blogs" link="/news-articles" />
            <div className="carousel-section">
                <div className="carousel-section mobile-full-carousel disable-arrows">
                    <Swiper
                        // install Swiper modules
                        modules={[A11y, FreeMode, Navigation]}
                        spaceBetween={10}
                        navigation
                        slidesPerView={1.3}
                        freeMode
                        >
                        <SwiperSlide>
                            <BlogCard title="Vedistry Prash for all Ages: Winter Health for whole familty" image={blog1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <BlogCard title="5 Immunity Boosting Herbs to beat viral Infection" image={blog2} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <BlogCard title="7 Ways to Improve Your Child’s & Adults Appetite" image={blog3} />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}