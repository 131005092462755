import React from "react";

export default function ClubMemberPlan (){
    return (
        <div className="club-member-plan">
            <div className="club-member-plan-section1">
                <div className="left-part">club member</div>
                <p className="club-mamber-para">Become a Vedistry club member & earn points on all orders</p>
            </div>
            <div className="club-member-plan-section2 pt-3">
                <div className="plan-card">
                    <div className="plan-align">
                    <h2 className="plan-card-heading">Delux Plan</h2>
                    <div className="price">₹299</div>
                    </div>
                  
                    {/* <NavLink to="/about" className="link" /> */}
                    <div><a href="/blog">View Plan Details</a></div>
                </div>
                <div className="plan-card">
                    <div className="plan-align">
                        <h2 className="plan-card-heading">Elite Plan</h2>
                        <div className="price">₹499</div>
                    </div>
                    <a href="/blog">View Plan Details</a>
                </div>
                
               
            </div>
            <div className="club-member-plan-btn">
                <button>Buy Plan</button>
                </div>
        </div>
    )
}