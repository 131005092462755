import React from "react";
import { Button, Form, InputGroup } from "react-bootstrap";

export default function Coupon(){
    return (
        <div className="coupon-div">
            <h5>Apply Coupon</h5>
            <InputGroup className="mb-3 input-align">
                <Form.Control
                    placeholder=""
                    aria-label="coupon code"
                    aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary coupon-btn" id="button-addon2">
                    Apply
                </Button>
            </InputGroup>
        </div>
    )
}