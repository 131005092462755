import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Blog5Image from "../assets/img/blog-5.png"
import Blog2Image from '../assets/img/blog-10.png';
import Blog3Image from '../assets/img/blog-11.png';
import Product from "../components/common/Product";
import ProductImage from '../assets/img/product.png';
import { A11y, FreeMode, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
 
export default function Blog() {
    const defaultValue = {
        title: 'Ashwagandha Plus Tablets',
        image: ProductImage,
        desc: 'A natural way to boost energy',
        price: '599',
        reivews: '153',
        rating: '4.4',
        highlight: 0
    };

    return(
        <Container>
            <Row>
                <Col xs={12}>
                    <h1>5 Immunity Herbs to beat Viral Infection </h1>
                    <img src={Blog5Image} alt="" className="img-fluid mb-3" />
                    <p>Feeling under the weather more often? You're not alone. These days, it seems like coughs and sniffles are showing up everywhere. But what if there was a natural way to strengthen your body's defense against these unwanted visitors?</p>
                    <p>Many people are turning to traditional wisdom for answers, and Ayurveda, the ancient Indian science of health, offers powerful secrets for building lasting immunity. Forget harsh chemicals and complicated routines – Ayurveda brings back simplicity with potent herbs that work in harmony with your body.</p>
                    <img src={Blog2Image} alt="" className="img-fluid mb-3" />
                    <p>Curious about some of these mighty plant warriors? In this blog, we'll discuss 5 star Ayurvedic herbs that can help you fight off those viral invaders. And while we're at it, we'll also explore safe and effective Ayurvedic immunity booster medicines like KOFOL tablets, offering an extra layer of protection that's been trusted for generations.</p>
                    <p>So, buckle up and get ready to discover how these natural superheroes can help you stay healthy and vibrant, season after season!</p>
                </Col>
                <Col xs={12}>
                    <h2>Top 5 Ayurvedic Immunity Boosters:</h2>
                    <div className="">
                        <p>Giloy: Your Immunity Buddy</p>
                        <ul>
                            <li><b>Properties</b>Giloy has impressive antiviral & anti-inflammator properties, making it a  powerhouse for immune support.</li>
                            <li><b>Benefits</b>This herb helps fight viruses, reduce inflammation, and strengthen your immune system against potential threats.</li>
                            <li><b>How to use</b>Get the benefits through decoctions, powders, or tablets.</li>
                        </ul>
                    </div>
                    <div className="">
                        <p>Tulsi: Nature's Infection Fighter</p>
                        <ul>
                            <li><b>Properties</b>Giloy has impressive antiviral & anti-inflammator properties, making it a  powerhouse for immune support.</li>
                            <li><b>Benefits</b>This herb helps fight viruses, reduce inflammation, and strengthen your immune system against potential threats.</li>
                            <li><b>How to use</b>Get the benefits through decoctions, powders, or tablets.</li>
                        </ul>
                    </div>
                    <div className="">
                        <p>Amla: Vitamin C Boost for Immunity</p>
                        <ul>
                            <li><b>Properties</b>Giloy has impressive antiviral & anti-inflammator properties, making it a  powerhouse for immune support.</li>
                            <li><b>Benefits</b>This herb helps fight viruses, reduce inflammation, and strengthen your immune system against potential threats.</li>
                            <li><b>How to use</b>Get the benefits through decoctions, powders, or tablets.</li>
                        </ul>
                    </div>
                    <div className="">
                        <p>Ashwagandha: Stress Buster, Immunity Booster</p>
                        <ul>
                            <li><b>Properties</b>Giloy has impressive antiviral & anti-inflammator properties, making it a  powerhouse for immune support.</li>
                            <li><b>Benefits</b>This herb helps fight viruses, reduce inflammation, and strengthen your immune system against potential threats.</li>
                            <li><b>How to use</b>Get the benefits through decoctions, powders, or tablets.</li>
                        </ul>
                    </div>
                    <div className="">
                        <p>Turmeric: The Golden Guardian of Health</p>
                        <ul>
                            <li><b>Properties</b>Giloy has impressive antiviral & anti-inflammator properties, making it a  powerhouse for immune support.</li>
                            <li><b>Benefits</b>This herb helps fight viruses, reduce inflammation, and strengthen your immune system against potential threats.</li>
                            <li><b>How to use</b>Get the benefits through decoctions, powders, or tablets.</li>
                        </ul>
                    </div>
                </Col>
                <Col xs={12}>
                    <p>Want to get the goodness of all these herbs at one time and without much hassle? </p>
                    <p>Yes! It is possible with KOFOL immunity tablets. Read further to know how.</p>
                    <h3>KOFOL Tablets: A Clinically Tested Ayurvedic Immunity Booster Medicine</h3>
                    <p>Yeah, you read it right! KOFOL Immunity Tablet is a Clinically Tested Immunity booster that has proven to be effective on 10 vital parameters of immunity. KOFOL tablet’s uses are not just limited to boosting immunity but they also help improve respiratory health, hence promoting faster recovery from infections. These tablets are made using herbs like Giloy, Haldi, Shunti, Pippali, Triphala guggul, & more. According to several studies, these herbs have shown promising results against viral infections.</p>
                    <p>Wish to know the results of the clinical studies? Well, they will surely meet all your expectations that you can have from an Ayurvedic immunity booster!</p>
                    <img src={Blog3Image} alt="" className="img-fluid mb-3" />
                    <p>According to the clinical studies, KOFOL Immunity tablets…</p>
                    <p>- Helps improve immunity by 3X</p>
                    <p>- Helps improve respiratory health by 6X</p>
                    <p>Still concerned about its safety? Don’t worry! It is formulated by doctors and is completely safe for both adults and kids. Adding to the safety point, it is manufactured by Charak Pharma, a 75-year-old Ayurvedic pharma company, that is trusted by people across India and even abroad.</p>
                    <p>For getting the best benefits of an Ayurvedic immunity booster tablet like KOFOL, it’s important to follow a specific routine. So here are some things that you can do along with taking these effective tablets.</p>
                    <p>- Have a healthy diet everyday</p>
                    <p>- Sleep for at least 7 to 8 hours</p>
                    <p>- Exercise daily and stay hydrated</p>
                    <p>- Manage stress using yoga, meditation</p>
                    <p>- Breathe good air quality and maintain hygiene</p>
                    <p>So to conclude, especially after the COVID-19 breakout, we all know how important immunity is to fight viral infections. And taking antibiotics or such harsh medicines is not a long-term solution. That’s why having Ayurvedic herbs that can boost your immunity is important these days and other KOFOL tablets are a good natural alternative. To be on the safest side, you can also consult doctors at Charak for free to know more about Ayurvedic remedies and immunity boosters. Ready to embrace the goodness of Ayurveda and build a strong immune system that protects you season after season? Order your pack of KOFOL immunity tablets today.</p>
                </Col>
                <Col xs={12} className="mt-5 mb-5">
                    <div className="inner-section">
                        <h2>You may also like</h2>
                        <Link to="/collections">View All</Link>
                    </div>
                    <div className="carousel-section">   
                        <Swiper
                        modules={[Scrollbar, A11y, FreeMode]}
                        spaceBetween={15}
                        slidesPerView={2}
                        scrollbar={{ draggable: true }}
                        >             
                            <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                            <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                            <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                            <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                        </Swiper>
                    </div>
                </Col>
            </Row>
        </Container>
    )
};