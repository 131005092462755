import React from "react";
// import Navbar from "./components/common/Header";
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Home from "./pages/";
import About from "./pages/about-us";
import Blogs from "./pages/blogs";
import Header from "./components/common/Header/Header";
import Footer from "./components/common/Footer/Footer";
import Collections from "./pages/collections";
import Products from "./pages/products";
import Blog from "./pages/blog";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/colors.css';
import './styles/fonts.css';
import './styles/global.css';


function App() {
  return (
    <>
      <Router>
        <Header />   
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="#/about" element={<About />} />
          <Route path="#/blogs" element={<Blogs />} />
          <Route path="#/collections" element={<Collections />} />
          <Route exact path="#/product/:id" element={<Products />} />
          <Route exact path="#/blog/:id" element={<Blog />} />
        </Routes>   
        <Footer />    
      </Router>
    </>
  );
}

export default App;
