import React, { useState } from 'react';

function Pagination() {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    // You can adjust the condition based on your total number of pages
    if (currentPage < 3) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <nav aria-label="Page navigation example">
      <ul className="pagination justify-content-center">
        <li className="page-item" onClick={goToPreviousPage} disabled={currentPage === 1}>
          <a className="page-link" href="#1">
            <span aria-hidden="true">&laquo;</span>
          </a>
        </li>
        <li onClick={() => handlePageChange(1)} className="page-item {currentPage === 2 ? 'active' : ''}">
          <a className="page-link" href="#2">1</a>
        </li>
        <li onClick={() => handlePageChange(2)} className="page-item {currentPage === 3 ? 'active' : ''}">
          <a className="page-link" href="#3">2</a>
        </li>
        <li onClick={() => handlePageChange(3)} className="page-item {currentPage === 1 ? 'active' : ''}">
          <a className="page-link" href="#4">3</a>
        </li>
        <li className="page-item" onClick={goToNextPage} disabled={currentPage === 3}>
          <a className="page-link" href="#5">
            <span aria-hidden="true">&raquo;</span>
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Pagination;
