import React from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import Blog4 from "../assets/img/blog-4.png"
import Blog5 from "../assets/img/blog-5.png"
import Blog6 from "../assets/img/blog-6.png"
import { A11y, FreeMode, Scrollbar } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import BlogItem from "../components/blogs/BlogItem";
 
export default function Blogs() {
    return(
        <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Nav variant="pills" className="flex-column d-block tabs-scroller mb-4 mt-4 mobile-full-carousel">
                    <Swiper
                    modules={[Scrollbar, A11y, FreeMode]}
                    spaceBetween={10}
                    slidesPerView={"auto"}
                    freeMode={true}
                    >
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="first">
                                    Skin
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="second">
                                    Bath & Body
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="third">
                                    Spa
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="fourth">
                                    Hair
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="fifth">
                                    Health Tips
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="sixth">
                                    Face
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="seventh">
                                    Sexual Wellness
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="eight">
                                    Single Herbs
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Nav.Item>
                                <Nav.Link eventKey="nine">
                                    Immunity & Throat
                                </Nav.Link>
                            </Nav.Item>
                        </SwiperSlide>
                    </Swiper>
                </Nav>
                <Tab.Content>
                    <Tab.Pane eventKey="first" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="third" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="sixth" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="seventh" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="eight" className="product-carousel">
                        <BlogItem image={Blog4} title="Vedistry Prash for all Ages: Winter Health for whole family" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog5} title="5 Immunity Boosting Herbs to beat viral Infection" link="/blog/vedistry-prash-for-all-ages" />
                        <BlogItem image={Blog6} title="7 Ways to Improve Your Child’s & Adults Appetite" link="/blog/7-ways-to-improve-your-childs-adults-appetite" />
                    </Tab.Pane>
                    <Tab.Pane eventKey="nine" className="product-carousel">
                    
                    </Tab.Pane>                    
                </Tab.Content>
            </Tab.Container>
        </Container>
    )
};