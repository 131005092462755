import React from "react";
import ProductImg from '../../assets/img/product.png';
import { Link } from "react-router-dom";
import { CloseButton } from "react-bootstrap";

export default function CartItem(){
    return (
        <div className="cart-item">
            <div className="cart-image">
                <img className="img-fluid -border" src={ProductImg} alt="Dipya" />
            </div>
            <div className="cart-title d-flex flex-wrap align-content-between">
                <h2 className="card-heading-text">Dipya Acidity Relief Tablets</h2>
                <p>60 Teblets / pc</p>
            </div>
            <div className="price-tag d-flex flex-wrap align-content-between">
                <Link className="remove-icon"><CloseButton /> Remove</Link>
                <div className="">
                    <span className="para-align"> <del className="del-text">₹299.00</del></span>
                    <span className="price-bold">₹299.00</span>
                </div>
            </div>
          
        </div>
    )
}