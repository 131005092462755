import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductImage from '../assets/img/product.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Product from "../components/common/Product";
import { Col, Nav, Tab } from "react-bootstrap";
import Pagination from "../components/common/Pagination";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode'
import { A11y, FreeMode, Scrollbar } from "swiper/modules";
import { Digestive, ImmunityThroat, Women, Men, Joint, Oral, Personal, Sexual, StressAnxietySleep } from '../components/common/Svg';

const defaultValue = {
    title: 'Ashwagandha Plus Tablets',
    image: ProductImage,
    desc: 'A natural way to boost energy',
    price: '599',
    reivews: '153',
    rating: '4.4',
    highlight: 0
};

export default function Collections(){
    return (
        <Container>
            <div className="tabs-scroller-cat proudct-colletions">
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <Nav variant="pills" className="flex-column d-block tabs-scroller mb-4 mt-4 mobile-full-carousel">
                        <Swiper
                        // install Swiper modules
                        modules={[Scrollbar, A11y, FreeMode]}
                        spaceBetween={10}
                        slidesPerView={"auto"}
                        freeMode={true}
                        >
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="first">
                                        <span className="icons">
                                            <ImmunityThroat />
                                        </span>
                                        Immunity & Throat Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">
                                        <span className="icons">
                                        <Joint />
                                        </span>
                                        Joint Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">
                                        <span className="icons">
                                            <StressAnxietySleep />
                                        </span>
                                        Stress Anxiety & Sleep Disturbance
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="fourth">
                                        <span className="icons">
                                            <Personal />
                                        </span>
                                        Personal Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="fifth">
                                        <span className="icons">
                                            <Women />
                                        </span>
                                        Women's Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="sixth">
                                        <span className="icons">
                                            <Digestive />
                                        </span>
                                        Digestive Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="seventh">
                                        <span className="icons">
                                        <Sexual />
                                        </span>
                                        Sexual Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="eight">
                                        <span className="icons">
                                        <Oral />
                                        </span>
                                        Oral Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="nine">
                                        <span className="icons">
                                        <Sexual />
                                        </span>
                                        Sexual Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Nav.Item>
                                    <Nav.Link eventKey="ten">
                                        <span className="icons">
                                        <Men />
                                        </span>
                                        Men's Health
                                    </Nav.Link>
                                </Nav.Item>
                            </SwiperSlide>
                        </Swiper>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="first" className="product-carousel">
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="sixth" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="seventh" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="eight" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="nine" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                        <Tab.Pane eventKey="ten" className="product-carousel">
                        <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                            <Row className="mb-4">
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            <Col xs={6}>
                            <Product data={defaultValue} />
                            </Col>
                            </Row>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <Pagination />
        </Container>
    )
}