import React from "react";
import MainSlider from "../components/slider/MainSlider";
import SummberDeals from "../components/home/SummerDeals";
import OurBestSeller from "../components/home/OurBestSeller";
import ImageTextButton from "../components/common/ImageTextButton";
import ShopByCategory from "../components/home/ShopByCategory";
import ClubMemberPlan from "../components/common/ClubMemberPlan";
import NewlyLaunched from "../components/home/NewlyLaunched";
import Consult from "../components/home/Consult";
import ShopByConcent from "../components/home/ShopByConcern";
import NewsSection from "../components/home/NewsSection";
import BlogSection from "../components/home/BlogSection";
import VideoSection from "../components/home/VideoSection";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

const Home = () => {
    return (
        <>
            <MainSlider />
            <Container>
                <Row>
                    <SummberDeals />
                    <OurBestSeller />
                    <ImageTextButton extraClass="mobile-full" />
                </Row>
            </Container>
            <ShopByCategory />
            <ClubMemberPlan />
            <Container>
                <Row>
                    <NewlyLaunched />
                    <Consult />
                    <ShopByConcent />
                    <VideoSection />
                    <BlogSection />
                    <NewsSection />
                </Row>
            </Container>
                
        </>
    );
};
 
export default Home;