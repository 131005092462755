import React from "react";
import { Link } from "react-router-dom";

export default function Ingredients({data}){
    return (
        <div className="ingredients">
            <div className="ingredients-part-1">
                <h2>{data.title}</h2>
                <Link to="#">Read more</Link>
            </div>
            <img src={data.image} alt="{data.title}" className="img-fluid" />
        </div>
    )
}