import React from "react";

export default function Card({title, desc, image}){
    return (
        <div className="card border-0">
            <img src={image} alt="card" />
            <div className="card-content">
            <h3>{title}</h3>
            <p>{desc}</p>
            </div>
        </div>
    )
}