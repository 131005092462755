import React from "react";
import Product from "../common/Product";

import ProductImage from '../../assets/img/product.png';
import HeadingViewAll from "../common/HeadingViewAll";

import { Scrollbar, A11y, FreeMode } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode'


export default function OurBestSeller(){
    const defaultValue = {
        title: 'Ashwagandha Plus Tablets',
        image: ProductImage,
        desc: 'A natural way to boost energy',
        price: '599',
        reivews: '153',
        rating: '4.4',
        highlight: 1
    };

    return (
        <div className="our-best-seller mt-5">
            <HeadingViewAll title="Our Bestsellers" link="/news-articles" />
            <div className="our-best-seller-grid-row product-carousel">
                <Swiper
                // install Swiper modules
                modules={[Scrollbar, A11y, FreeMode]}
                spaceBetween={15}
                slidesPerView={2}
                scrollbar={{ draggable: true }}
                >
                    <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                    <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                    <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                    <SwiperSlide><Product data={defaultValue} /></SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}