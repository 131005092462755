import React from "react";
import consultImage from '../../assets/img/consultimg.png'
import HeadingViewAll from './../common/HeadingViewAll';

export default function Consult(){
    return (
        <div className="consult mt-5">
            <div className="inner-section">
                <HeadingViewAll title="Speak to our Ayurveda Experts" link="" />
            </div>
            <div className="carousel-section">
                <img src={consultImage} alt="Consult" className="img-fluid w-100" />
                <button className="primary primary-border consult-btn mt-4">Consult for Free</button>
            </div>
        </div>
    )
}