import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from '../../../assets/img/logo-trans.png';
import SocialIcons from "../socialIcon";
import './Footer.css';

export default function Footer(){
    return (
        <footer>
            <Container>
                <Row>
                    <Col sm>
                        <div className="footer-image">
                            <img src={logo} alt="Logo" />
                        </div>
                        <div className="marketing-add">
                            <h2>Marketing Office</h2>
                            <p>Vedistry Pvt. Ltd.</p>
                            <p>501/a, Poonam Chambers, Dr. Annie Besant Road,</p>
                            <p>Worli, Mumbai - 400 018</p>
                        </div>
                        <div className="menufacurer-add">
                            <h2>Country of Origin: India</h2>
                            <p>Manufacturer: Charak Pharma Pvt Ltd, 21, Evergreen Industrial Estate, 2nd Floor, Opposite-Dr. E. Moses Road, Shakti Mills, Lower Parel, Mumbai - 400 011</p>
                        </div>
                        <div className="footer-contact">
                            <p>+91 86574 51581</p>
                            <p>(Monday - Friday : 9:00 AM to 6:00 PM)</p>
                        </div>
                    </Col>
                    <Col sm>
                        <h2 className="heading-text">Products</h2>
                        <ul className="product-list">
                            <li><a href="#immunity">Immunity</a></li>
                            <li><a href="#jointhealth">Joint Health</a></li>
                            <li><a href="#digestivecare">Digestive Care</a></li>
                            <li><a href="#womenscare">Women’s Health Care</a></li>
                            <li><a href="#personalcare">Personal Care</a></li>                            
                        </ul>
                    </Col>
                    <Col sm>
                        <h2 className="heading-text">Menu</h2>
                        <ul className="product-list">
                            <li><a href="#returncancellation">Returns & Cancellation Policy</a></li>
                            <li><a href="#termscondition">Terms & Conditions</a></li>
                            <li><a href="#privacypolicy">Privacy Policy</a></li>
                            <li><a href="#disclaimer">Disclaimer</a></li>
                            <li><a href="#contact">Contact</a></li>                            
                            <li><a href="#healthpoint">Health Points Terms & Conditions</a></li>                            
                        </ul>
                    </Col>
                    <Col sm>
                        <h2 className="heading-text">Follow Us:</h2>
                        <SocialIcons />
                    </Col>
                </Row>
            </Container>            
        </footer>
    )
}