import React from "react";
import RightArrow from "./RightArrow";
import { Link } from "react-router-dom";

export default function HeadingViewAll({ title, link }){
    return(
        <div className="heading-view-all-section">
            <h2 className="pb-2">{title}</h2>
            {link && 
                <Link to={link}>
                    View All&nbsp;&nbsp;
                    <RightArrow />
                </Link>            
            }
        </div>
    )
}