import React from "react";

export default function RightArrow(){
    return (
        <>
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.16975 5.68847L1.37379 1.11884C1.29376 1.04256 1.18745 1 1.07689 1C0.966331 1 0.860015 1.04256 0.779992 1.11884L0.774828 1.124C0.7359 1.16099 0.704903 1.2055 0.683722 1.25484C0.662539 1.30418 0.651616 1.35731 0.651616 1.411C0.651616 1.4647 0.662539 1.51783 0.683722 1.56717C0.704903 1.61651 0.7359 1.66102 0.774828 1.698L5.2911 6.00086L0.774828 10.302C0.7359 10.339 0.704903 10.3835 0.683721 10.4328C0.662539 10.4822 0.651616 10.5353 0.651616 10.589C0.651616 10.6427 0.662539 10.6958 0.683721 10.7452C0.704903 10.7945 0.7359 10.839 0.774828 10.876L0.779992 10.8812C0.860014 10.9574 0.96633 11 1.07689 11C1.18745 11 1.29376 10.9574 1.37379 10.8812L6.16975 6.31153C6.21193 6.27134 6.2455 6.22301 6.26845 6.16946C6.29139 6.11591 6.30322 6.05826 6.30322 6C6.30322 5.94174 6.29139 5.88409 6.26845 5.83054C6.2455 5.77699 6.21193 5.72866 6.16975 5.68847Z" fill="#545454" stroke="#545454" strokeWidth="0.5"/>
        </svg>
        </>
    )
}