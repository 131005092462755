import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import About1 from "../assets/img/about-1.png";
import About2 from "../assets/img/about-2.png";
import About3 from "../assets/img/about-3.png";

import MillionsCustomersImage from "../assets/img/millions-customers.png";
import IsoCertifiedImage from "../assets/img/iso-certified.png";
import WHOGMPImage from "../assets/img/who-gmp.png";
import StandardizedExtractsImage from "../assets/img/standardized-extracts.png";
import QualityProductsImage from "../assets/img/quality-products.png";
import FormulatedDoctorsImage from "../assets/img/formulated-by-Doctors.png";
import GreatResultsImage from "../assets/img/great-Results.png";
import AffordableImage from "../assets/img/Affordable.png";
 
const About = () => {
    return (
        <>
            <div className="">
                <img className="img-fluid" src={About1} alt="About-1" />
            </div>
            <div className="topbar notification w-100 dark-theme d-flex align-items-center mt-3 mb-3">
                <div className="marquee-tick d-flex align-items-center">
                    <div className="track d-flex">
                        <div className="content">&nbsp;<img src={QualityProductsImage} alt="Quality Products" /> Quality Products</div>
                        <div className="content">&nbsp;<img src={FormulatedDoctorsImage} alt="Formulated by Doctors" /> Formulated by Doctors</div>
                        <div className="content">&nbsp;<img src={GreatResultsImage} alt="Great Results" /> Great Results</div>
                        <div className="content">&nbsp;<img src={AffordableImage} alt="Affordable" /> Affordable</div>
                    </div>
                </div>
            </div>
            <div className="">
                <img className="img-fluid" src={About2} alt="About-2" />
            </div>
            <div className="topbar notification w-100 dark-theme d-flex align-items-center mt-3 mb-3">
                <div className="marquee-tick d-flex align-items-center">
                    <div className="track d-flex">
                        <div className="content">&nbsp;<img src={MillionsCustomersImage} alt="Millions of Satisfied Customers" /> Millions of Satisfied Customers</div>
                        <div className="content">&nbsp;<img src={IsoCertifiedImage} alt="ISO Certified" /> ISO Certified</div>
                        <div className="content">&nbsp;<img src={WHOGMPImage} alt="WHO GMP" /> WHO GMP</div>
                        <div className="content">&nbsp;<img src={StandardizedExtractsImage} alt="Standardized Extracts" /> Standardized Extracts</div>
                    </div>
                </div>
            </div>
            <div className="">
                <img className="img-fluid" src={About3} alt="About-3" />
            </div>
            <Container>
                <Row>
                    <Col className="mb-5 mt-4">
                        <p>Vedistry, part of Charak Group - pioneers in Ayurveda for 75 years is dedicated to improving the lives of consumers by offering high-quality products for their health & wellness. </p>
                        <p>We aim to combine the best of Ayurveda and Modern Science for the benefit of mankind with assured quality-driven products. </p>
                        <p>Vedistry is the result of Charak’s foray into the personal & healthcare market. With a legacy that spans over 75 years, Charak has established itself as one of the foremost Ayurvedic Pharma companies, with top-quality products and services. On the back of this expertise, Vedistry has expanded our reach on OTT platform with a range of high-quality consumer brands.Vedistry offers flagship brands like moha:, KOFOL, GO365 & DIPYA with a vision to give highly efficient yet chemical-free products for consumers’ well-being.</p>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
 
export default About;