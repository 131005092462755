import React from "react";
import HeadingViewAll from "../common/HeadingViewAll";
import { A11y, FreeMode, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import video_img from '../../assets/img/video-1.jpg';
import video_2 from '../../assets/img/video-2.jpg';
import video_3 from '../../assets/img/video-4.jpg';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';

export default function VideoSection(){
    return (
        <div className="video-section mt-5">
            <HeadingViewAll title="Vedistry TV" link="/vedistry-tv" />
            <div className="carousel-section">
                <div className="carousel-section mobile-full-carousel disable-arrows">
                    <Swiper
                        // install Swiper modules
                        modules={[A11y, FreeMode, Navigation]}
                        spaceBetween={10}
                        navigation
                        slidesPerView={1.3}
                        freeMode
                        >
                        <SwiperSlide>
                            <div className="card-image position-relative">
                                <div className="img">
                                    <img src={video_img} className="img-fluid" alt="Video1" />
                                </div>
                                <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8555 22.7267V12.2734C13.8557 12.1426 13.8911 12.0143 13.9579 11.9018C14.0248 11.7894 14.1207 11.6971 14.2355 11.6344C14.3503 11.5718 14.4798 11.5412 14.6105 11.5459C14.7413 11.5505 14.8683 11.5902 14.9784 11.6609L23.1101 16.8861C23.213 16.952 23.2977 17.0428 23.3564 17.1501C23.4151 17.2574 23.4459 17.3777 23.4459 17.5C23.4459 17.6223 23.4151 17.7426 23.3564 17.8499C23.2977 17.9572 23.213 18.048 23.1101 18.114L14.9784 23.3407C14.8683 23.4113 14.7413 23.451 14.6105 23.4556C14.4798 23.4603 14.3503 23.4297 14.2355 23.3671C14.1207 23.3044 14.0248 23.2121 13.9579 23.0997C13.8911 22.9873 13.8557 22.8589 13.8555 22.7282V22.7267Z" fill="#F6F6F6"/>
<path d="M1.45703 17.5002C1.45703 8.64079 8.63932 1.4585 17.4987 1.4585C26.3581 1.4585 33.5404 8.64079 33.5404 17.5002C33.5404 26.3595 26.3581 33.5418 17.4987 33.5418C8.63932 33.5418 1.45703 26.3595 1.45703 17.5002ZM17.4987 3.646C13.8243 3.646 10.3005 5.10563 7.70232 7.70379C5.10416 10.3019 3.64453 13.8258 3.64453 17.5002C3.64453 21.1745 5.10416 24.6984 7.70232 27.2965C10.3005 29.8947 13.8243 31.3543 17.4987 31.3543C21.173 31.3543 24.6969 29.8947 27.2951 27.2965C29.8932 24.6984 31.3529 21.1745 31.3529 17.5002C31.3529 13.8258 29.8932 10.3019 27.2951 7.70379C24.6969 5.10563 21.173 3.646 17.4987 3.646Z" fill="#F6F6F6"/>
</svg>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-image position-relative">
                                <div className="img">
                                    <img src={video_2} className="img-fluid" alt="Video2" />
                                </div>
                                <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8555 22.7267V12.2734C13.8557 12.1426 13.8911 12.0143 13.9579 11.9018C14.0248 11.7894 14.1207 11.6971 14.2355 11.6344C14.3503 11.5718 14.4798 11.5412 14.6105 11.5459C14.7413 11.5505 14.8683 11.5902 14.9784 11.6609L23.1101 16.8861C23.213 16.952 23.2977 17.0428 23.3564 17.1501C23.4151 17.2574 23.4459 17.3777 23.4459 17.5C23.4459 17.6223 23.4151 17.7426 23.3564 17.8499C23.2977 17.9572 23.213 18.048 23.1101 18.114L14.9784 23.3407C14.8683 23.4113 14.7413 23.451 14.6105 23.4556C14.4798 23.4603 14.3503 23.4297 14.2355 23.3671C14.1207 23.3044 14.0248 23.2121 13.9579 23.0997C13.8911 22.9873 13.8557 22.8589 13.8555 22.7282V22.7267Z" fill="#F6F6F6"/>
<path d="M1.45703 17.5002C1.45703 8.64079 8.63932 1.4585 17.4987 1.4585C26.3581 1.4585 33.5404 8.64079 33.5404 17.5002C33.5404 26.3595 26.3581 33.5418 17.4987 33.5418C8.63932 33.5418 1.45703 26.3595 1.45703 17.5002ZM17.4987 3.646C13.8243 3.646 10.3005 5.10563 7.70232 7.70379C5.10416 10.3019 3.64453 13.8258 3.64453 17.5002C3.64453 21.1745 5.10416 24.6984 7.70232 27.2965C10.3005 29.8947 13.8243 31.3543 17.4987 31.3543C21.173 31.3543 24.6969 29.8947 27.2951 27.2965C29.8932 24.6984 31.3529 21.1745 31.3529 17.5002C31.3529 13.8258 29.8932 10.3019 27.2951 7.70379C24.6969 5.10563 21.173 3.646 17.4987 3.646Z" fill="#F6F6F6"/>
</svg>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="card-image position-relative">
                                <div className="img">
                                    <img src={video_3} className="img-fluid" alt="Video3" />
                                </div>
                                <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8555 22.7267V12.2734C13.8557 12.1426 13.8911 12.0143 13.9579 11.9018C14.0248 11.7894 14.1207 11.6971 14.2355 11.6344C14.3503 11.5718 14.4798 11.5412 14.6105 11.5459C14.7413 11.5505 14.8683 11.5902 14.9784 11.6609L23.1101 16.8861C23.213 16.952 23.2977 17.0428 23.3564 17.1501C23.4151 17.2574 23.4459 17.3777 23.4459 17.5C23.4459 17.6223 23.4151 17.7426 23.3564 17.8499C23.2977 17.9572 23.213 18.048 23.1101 18.114L14.9784 23.3407C14.8683 23.4113 14.7413 23.451 14.6105 23.4556C14.4798 23.4603 14.3503 23.4297 14.2355 23.3671C14.1207 23.3044 14.0248 23.2121 13.9579 23.0997C13.8911 22.9873 13.8557 22.8589 13.8555 22.7282V22.7267Z" fill="#F6F6F6"/>
<path d="M1.45703 17.5002C1.45703 8.64079 8.63932 1.4585 17.4987 1.4585C26.3581 1.4585 33.5404 8.64079 33.5404 17.5002C33.5404 26.3595 26.3581 33.5418 17.4987 33.5418C8.63932 33.5418 1.45703 26.3595 1.45703 17.5002ZM17.4987 3.646C13.8243 3.646 10.3005 5.10563 7.70232 7.70379C5.10416 10.3019 3.64453 13.8258 3.64453 17.5002C3.64453 21.1745 5.10416 24.6984 7.70232 27.2965C10.3005 29.8947 13.8243 31.3543 17.4987 31.3543C21.173 31.3543 24.6969 29.8947 27.2951 27.2965C29.8932 24.6984 31.3529 21.1745 31.3529 17.5002C31.3529 13.8258 29.8932 10.3019 27.2951 7.70379C24.6969 5.10563 21.173 3.646 17.4987 3.646Z" fill="#F6F6F6"/>
</svg>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}