import React from "react";
import image from '../../assets/img/discover.jpg';
import HeadingViewAll from "./HeadingViewAll";

export default function ImageTextButton({extraClass}){
    return (
        <div className="image-text-button-section">
            <HeadingViewAll title="Discovering our Roots" link="" />
            <div className={`image-text-inner-section ${extraClass}`}>
                <img className="img-fluid w-100" src={image} alt="abcd" />
                <div className="content">
                    <p className="pt-3 image-text-inner-section-para "><i className="fw-semibold">Vedistry </i>is an offshoot of Charak Pharma established in 2018 in order to be present in D2C (direct-to-consumer) segment. Vedistry derives its strengths from the over 7 decades of experience of manufacturing and marketing of Ayurvedic Products by Charak Pharma</p>
                    <button className="mt-4">Discover Us</button>
                </div>
            </div>
        </div>
    )
}