import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import './Slider.css'; // Import header styles
import heroImage from '../../assets/img/hero-image.png'; 

export default function MainSlider() {
  var settings = {
    autoplay: false,
    autoplaySpeed: 3000,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <>
    <Slider {...settings} className="hero-slide">
        <div className="slide-grid">
            <div className="sliderImage">
                <div className="video-play position-relative">
                    <img src={heroImage} className="img-fluid w-100" alt="Hero" />
                    <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                        
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.83203 22.0002C1.83203 10.8627 10.8612 1.8335 21.9987 1.8335C33.1362 1.8335 42.1654 10.8627 42.1654 22.0002C42.1654 33.1377 33.1362 42.1668 21.9987 42.1668C10.8612 42.1668 1.83203 33.1377 1.83203 22.0002Z" fill="#F6F6F6"/>
<path d="M17.418 28.5706V15.4293C17.4182 15.2649 17.4627 15.1036 17.5468 14.9622C17.6308 14.8209 17.7513 14.7048 17.8957 14.6261C18.04 14.5474 18.2029 14.5089 18.3672 14.5147C18.5315 14.5206 18.6912 14.5705 18.8296 14.6593L29.0523 21.2281C29.1817 21.3111 29.2882 21.4252 29.362 21.5601C29.4358 21.695 29.4744 21.8462 29.4744 22C29.4744 22.1537 29.4358 22.305 29.362 22.4398C29.2882 22.5747 29.1817 22.6889 29.0523 22.7718L18.8296 29.3425C18.6912 29.4313 18.5315 29.4812 18.3672 29.487C18.2029 29.4929 18.04 29.4544 17.8957 29.3757C17.7513 29.2969 17.6308 29.1808 17.5468 29.0395C17.4627 28.8982 17.4182 28.7369 17.418 28.5725V28.5706Z" fill="#252525"/>
</svg>

                    </div>
                </div>
            </div>
        </div>
        <div className="slide-grid">
            <div className="sliderImage">
            <div className="video-play position-relative">
                    <img src={heroImage} className="img-fluid w-100" alt="Hero" />
                    <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                        
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.83203 22.0002C1.83203 10.8627 10.8612 1.8335 21.9987 1.8335C33.1362 1.8335 42.1654 10.8627 42.1654 22.0002C42.1654 33.1377 33.1362 42.1668 21.9987 42.1668C10.8612 42.1668 1.83203 33.1377 1.83203 22.0002Z" fill="#F6F6F6"/>
<path d="M17.418 28.5706V15.4293C17.4182 15.2649 17.4627 15.1036 17.5468 14.9622C17.6308 14.8209 17.7513 14.7048 17.8957 14.6261C18.04 14.5474 18.2029 14.5089 18.3672 14.5147C18.5315 14.5206 18.6912 14.5705 18.8296 14.6593L29.0523 21.2281C29.1817 21.3111 29.2882 21.4252 29.362 21.5601C29.4358 21.695 29.4744 21.8462 29.4744 22C29.4744 22.1537 29.4358 22.305 29.362 22.4398C29.2882 22.5747 29.1817 22.6889 29.0523 22.7718L18.8296 29.3425C18.6912 29.4313 18.5315 29.4812 18.3672 29.487C18.2029 29.4929 18.04 29.4544 17.8957 29.3757C17.7513 29.2969 17.6308 29.1808 17.5468 29.0395C17.4627 28.8982 17.4182 28.7369 17.418 28.5725V28.5706Z" fill="#252525"/>
</svg>

                    </div>
                </div>
            </div>
        </div>
        <div className="slide-grid">
        <div className="video-play position-relative">
                    <img src={heroImage} className="img-fluid w-100" alt="Hero" />
                    <div className="overlay-content position-absolute top-50 start-50 translate-middle">
                        
<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.83203 22.0002C1.83203 10.8627 10.8612 1.8335 21.9987 1.8335C33.1362 1.8335 42.1654 10.8627 42.1654 22.0002C42.1654 33.1377 33.1362 42.1668 21.9987 42.1668C10.8612 42.1668 1.83203 33.1377 1.83203 22.0002Z" fill="#F6F6F6"/>
<path d="M17.418 28.5706V15.4293C17.4182 15.2649 17.4627 15.1036 17.5468 14.9622C17.6308 14.8209 17.7513 14.7048 17.8957 14.6261C18.04 14.5474 18.2029 14.5089 18.3672 14.5147C18.5315 14.5206 18.6912 14.5705 18.8296 14.6593L29.0523 21.2281C29.1817 21.3111 29.2882 21.4252 29.362 21.5601C29.4358 21.695 29.4744 21.8462 29.4744 22C29.4744 22.1537 29.4358 22.305 29.362 22.4398C29.2882 22.5747 29.1817 22.6889 29.0523 22.7718L18.8296 29.3425C18.6912 29.4313 18.5315 29.4812 18.3672 29.487C18.2029 29.4929 18.04 29.4544 17.8957 29.3757C17.7513 29.2969 17.6308 29.1808 17.5468 29.0395C17.4627 28.8982 17.4182 28.7369 17.418 28.5725V28.5706Z" fill="#252525"/>
</svg>

                    </div>
                </div>
            
        </div>
    </Slider>
    <div className="slide-bottom-content slideContent p-2 pt-30 text-center pb-3">
        <p>Our greatest aim at <b className="bold-text"> <i> Vedistry</i></b> is to deliver best of Ayurveda, <b className="bold-text"> <i> modern science magic & quality driven </i></b> products.</p>
        <button className="primary primary-border shop-btn mt-3">Shop Now</button>
    </div>
    </>
  );
}