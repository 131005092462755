import React from "react";

export default function BlogCard({ title, image }){
    return (
        <div className="blog-card">
            <img src={image} alt="Blog" className="img-fluid" />
            <h2 className="blog-card-content pt-3">{title}</h2>
            <a href="/about">Read More</a>
        </div>
    )
}