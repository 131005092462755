import React from "react";
import { ProgressBar } from "react-bootstrap";
import '../../assets/css/customer-review.css'
import Image from '../../assets/img/customer-review.png';

export default function CustomerReview(){
    return (
        <div className="review-section mb-5">
            <div className="review-average">
                <div className="review-average-section review-average-left">
                    <div className="review-average-count">4.4</div>
                    <div className="review-average-stars">
                        <svg width="18" height="17" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                        </svg>
                        <svg width="18" height="17" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                        </svg>
                        <svg width="18" height="17" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                        </svg>
                        <svg width="18" height="17" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                        </svg>
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.99935 13.2657L14.1493 16.374L12.7827 10.5157L17.3327 6.57404L11.341 6.06571L8.99935 0.54071L6.65768 6.06571L0.666016 6.57404L5.21602 10.5157L3.84935 16.374L8.99935 13.2657Z" fill="#252525" fill-opacity="0.5"/>
                        </svg>
                    </div>
                </div>
                <div className="review-average-section review-average-right">
                    <ul>
                        <li>5 <ProgressBar now={100} /></li>
                        <li>4<ProgressBar now={80} /></li>
                        <li>3<ProgressBar now={60} /></li>
                        <li>2<ProgressBar now={40} /></li>
                        <li>1<ProgressBar now={20} /></li>
                    </ul>
                </div>
            </div>
            <button className="primary custom-primary-btn mt-5 mb-5">Write a Review</button>
            <div className="review-list">
                <div className="review-list-grid mb-4">
                    <div className="review-title">
                        <div className="review-title-left">
                            <img src={Image} alt="Customer" />
                            <p>Geeta Sharma</p>
                        </div>                        
                        <div className="reviews">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                        </div>
                    </div>
                    <div className="review-description">
                        <div className="review-desc-title">Great Acidity Relief!</div>
                        <p>I am able to notice a difference after one week of consistent dosage</p>
                    </div>
                </div>
                <div className="review-list-grid mb-4">
                    <div className="review-title">
                        <div className="review-title-left">
                            <img src={Image} alt="Customer" />
                            <p>Ashmi Shah</p>
                        </div>
                        <div className="reviews">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.18861 10.4039L3.06694 6.64057L0.146484 4.1104L3.99344 3.77749L5.50007 0.228027L7.00669 3.77678L10.8529 4.10969L7.93319 6.63986L8.81153 10.4032L5.50007 8.40573L2.18861 10.4039Z" fill="#545454"/>
                            </svg>
                        </div>
                    </div>
                    <div className="review-description">
                        <div className="review-desc-title">Outstanding Product</div>
                        <p>Good enough - manages acidity well for both long term and sudden spikes.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}